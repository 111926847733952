.App {
  padding: 10px 16px;
  max-width: 700px;
}

.App h3, .App h4 {
  margin-top: 1rem;
}

li.unchecked {
  list-style-image: url(../public/unchecked-square.svg);
}

li.checked {
  list-style-image: url(../public/checked-square.svg);
}
